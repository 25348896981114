import {
  BarChartHorizontal,
  BarChartHorizontalStacked,
  BarChartVertical,
  BarChartVerticalComparative,
  BarChartVerticalStacked,
  CardNumOverview,
  DoughnutChart,
  LineChart,
} from "@/components/molecules";
type GraphicItemProps = { graphicType: string; data?: any };

export const OverviewItem = ({ graphicType, data }: GraphicItemProps) => {
  switch (graphicType) {
    case "line":
      return <LineChart data={data} />;
    case "horizontal_bar":
      return <BarChartHorizontal data={data} />;
    case "vertical_bar_comparative":
      return <BarChartVerticalComparative data={data} />;
    case "horizontal_bar_stacked":
      return <BarChartHorizontalStacked data={data} />;
    case "doughnut":
      return <DoughnutChart data={data} />;
    case "vertical_bar":
      return <BarChartVertical data={data} />;
    case "vertical_bar_stacked":
      return <BarChartVerticalStacked data={data} />;
    default:
      return (
        <CardNumOverview
          variant={"defaultValue"}
          className="w-1/2 aspect-square items-center"
          number={data}
        />
      );
  }
};
