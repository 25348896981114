import {
  WasteCategoriesId,
  WasteId,
  collectionCenterValidations,
} from "@/interfaces";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  useWatch,
} from "react-hook-form";
import { ValidationGroupButton } from "../ValidationGroupButton/ValidationGroupButton";
import { DialogAdmins } from "@/components/atoms";
import { ValidationsImages } from "../ValidationsImages/ValidationsImages";
import { RowInputDataCCValidation } from "../RowInputDataCCValidation/RowInputDataCCValidation";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { ControlledInput } from "../ControlledInput/ControlledInput";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { SelectWithDefault } from "../SelectWithDefault/SelectWithDefault";
import { CampaignsOptions } from "@/constants/campaignsData";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { CirclePlus } from "lucide-react";

type ViewDetailsDialogCCValidationProps = {
  isOpen?: boolean;
  onClose?: (() => void) | undefined;
  handleRemove: () => void;
  handleRejected: () => void;
  isEditable?: boolean;
  fields: FieldArrayWithId<
    collectionCenterValidations,
    "wastes_validations",
    "id"
  >[];
  control: Control<collectionCenterValidations, any>;
  errors: FieldErrors<collectionCenterValidations>;
  handleSubmit: () => void;
  wastes: WasteId[];
  badges: CampaignsOptions[];
  waste_categories: WasteCategoriesId[];
  handleAddNewWaste: () => void;
};

export const ViewDetailsDialogCCValidation = ({
  isOpen = false,
  onClose = () => {},
  handleRemove,
  handleRejected,
  isEditable,
  fields,
  control,
  errors,
  handleSubmit,
  waste_categories,
  wastes,
  badges,
  handleAddNewWaste,
}: ViewDetailsDialogCCValidationProps) => {
  const name_collection_center = useWatch({
    control: control,
    name: "name_collection_center",
  });
  const ad = useWatch({ control: control, name: "name_collection_center" });

  return (
    <DialogAdmins
      title="Detalles"
      variant={"green"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Controller
        name={"evidence"}
        control={control}
        render={({ field: { name, onChange, value } }) => (
          <TransformWrapper>
            <TransformComponent>
              <ValidationsImages image={value} />
            </TransformComponent>
          </TransformWrapper>
        )}
      />
      <div className="w-full flex justify-center items-center">
        {isEditable && (
          <Button
            className="mx-10 my-7 justify-self-start bg-admins-button-green rounded-lg text-md w-fit"
            onClick={handleAddNewWaste}
          >
            <CirclePlus className="text-white mx-4 w-5 h-5" />
            Agregar Residuo
          </Button>
        )}
      </div>
      <div className="grid grid-cols-7 w-full gap-3 text-ecolana-black text-center px-3">
        <p className=" col-start-2 col-span-3 font-semibold">Cantidad</p>
        <p className=" col-span-3 font-semibold ">Residuo</p>
      </div>
      <div className="grid grid-cols-7 w-full gap-4 text-ecolana-black text-center px-3 overflow-y-auto min-h-48 max-h-52 mb-7">
        {fields.map((item, idx) => (
          <Controller
            key={`fields_${item.id}_${idx}`}
            name={`wastes_validations.${idx}._destroy`}
            control={control}
            render={({ field: { name, value, onChange } }) =>
              !value ? (
                <RowInputDataCCValidation
                  key={`${item.id}_validation_row_${idx}`}
                  handleRemoveSkuFromList={onChange}
                  control={control}
                  errors={errors}
                  isActive={isEditable}
                  idx={idx}
                  waste_categories={waste_categories}
                  wastes={wastes}
                />
              ) : (
                <></>
              )
            }
          />
        ))}
      </div>
      <div className="grid grid-cols-3 gap-4 justify-center px-4">
        <Label
          htmlFor="date_register"
          className="text-admins-text-active flex items-center justify-center"
        >
          Fecha de Registro
        </Label>
        <ControlledInput
          label={"Fecha de Registro"}
          name={`date_insert`}
          control={control}
          variant={"adminGray"}
          placeholder={"Fecha de Registro"}
          className="col-span-2"
          disabled
        />
        <Label
          htmlFor="purchase_place"
          className="text-admins-text-active flex items-center justify-center"
        >
          ID de Usuario
        </Label>
        <ControlledInput
          label={"ID de Usuario"}
          name={`id_user`}
          control={control}
          variant={"adminGray"}
          placeholder={"ID de Usuario"}
          className="col-span-2"
          disabled
        />
        <Controller
          name="user_name"
          control={control}
          render={({ field: { name, value, onChange } }) =>
            value ? (
              <>
                <Label
                  htmlFor="purchase_place"
                  className="text-admins-text-active flex items-center justify-center"
                >
                  Nombre de usuario
                </Label>
                <ControlledInput
                  label={"ID de Usuario"}
                  name={`user_name`}
                  control={control}
                  variant={"adminGray"}
                  placeholder={"ID de Usuario"}
                  className="col-span-2"
                  disabled
                />
              </>
            ) : (
              <></>
            )
          }
        />
        <Label
          htmlFor="purchase_place"
          className="text-admins-text-active flex items-center justify-center"
        >
          Insignia
        </Label>
        <Controller
          name={"campaign_id"}
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <SelectWithDefault
              value={value}
              name={name}
              setValue={onChange}
              id="campaign_id"
              variant={"adminGreen"}
              className="col-span-2 w-full text-sm"
              selectedItems={badges}
              placeHolder={"Selecciona una opción..."}
            />
          )}
        />
      </div>
      {isEditable && (
        <ValidationGroupButton
          handleSubmit={handleSubmit}
          handleRemove={handleRemove}
          handleRejected={handleRejected}
        />
      )}
    </DialogAdmins>
  );
};
