import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import React from "react";
import { DataPerCardMKT } from "../DataPerCardMKT/DataPerCardMKT";
import { TableCardWastes } from "../TableCardWastes/TableCardWastes";
import { dataGraphicsType } from "@/interfaces";
import { WasteValidationsContainer } from "@/components/molecules";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";

const dataGraphicsRewardApp: dataGraphicsType = [
  {
    title: "Stock INICIAL de recompensas",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "doughnut",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Control de recompensas otorgadas",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar_stacked",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Recompensas otorgadas",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "vertical_bar_comparative",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "No. Usuarios VS KG para cumplir recompensas",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar",
    name_graphic_data: "validation_center_types",
  },
];
export const RewardBadgeTab = () => {
  return (
    <TabsContent value="rewards" className="w-full grid grid-cols-2 gap-4 px-3">
      <div className="flex flex-col">
        <p className="text-admins-text-active font-bold">
          Información de la insignia
        </p>
        <Textarea variant={"adminGray"} />
      </div>
      <TableCardWastes />
      {dataGraphicsRewardApp.map((item, idx) => (
        <WasteValidationsContainer
          key={`graphicCell_${idx * Math.random() * 100}`}
          className={`${
            item.span == 1 ? "" : "col-span-2"
          } bg-admins-centers bg-opacity-15`}
          title={item.title}
          subtitle={item.subtitle}
          filter={
            //   <FilterPerYearController
            //     name={item.nameFilter}
            //     control={control}
            //   />
            <></>
          }
        >
          <OverviewItem
            graphicType={item.graphicType}
            data={
              // generateGraphic(
              //   stats_collection_centers as any,
              //   item.name_graphic_data,
              //   false,
              //   true
              // ) as any
              undefined
            }
          />
        </WasteValidationsContainer>
      ))}
      <TableCardWastes />
    </TabsContent>
  );
};
