import React, { FC, useEffect, useState } from "react";
import {
  ecolanitaMapDialogImg,
  miniBanner,
  miniBanner2,
  miniBanner3,
  miniBanner4,
} from "../../../assets";
import { Button } from "../shadcn/ui/button";
import Image from "next/image";
import { CarouselCampaignImages } from "@/components/molecules";
import { X } from "lucide-react";

interface Props {
  position?: "sticky" | "absolute";
}

const imgCarouselBanner = [
  { img: miniBanner },
  { img: miniBanner2 },
  { img: miniBanner3 },
  { img: miniBanner4 },
];

export const StickyBanner: FC<Props> = ({ position = "absolute" }) => {
  const [showBanner, setShowBanner] = useState(false);
  //const [value, setValue] = useSessionStorage("show-banner", 1);
  useEffect(() => {
    setTimeout(() => {
      setShowBanner(true);
    }, 1000);
  }, []);
  return (
    <section
      className={` w-full z-[30] bottom-[0svh] transition-opacity ease-out duration-500 overflow-hidden  justify-around items-center bg-green-700 p-4 ${position} ${
        showBanner
          ? "flex lg:flex-row flex-col h-auto opacity-70"
          : "h-0 opacity-0"
      }`}
    >
      <div
        className="absolute lg:hidden top-1 right-1 bg-gradient-to-tr from-[#41B59A] to-[#1C7D6B] rounded-full min-w-fit p-1"
        onClick={() => setShowBanner(false)}
      >
        <X className="text-white font-bold w-5 h-5" />
      </div>
      <div className="w-full lg:w-1/3 flex flex-row lg:flex-col justify-between items-center lg:items-start space-y-0 lg:space-y-16 px-10">
        <h2 className="lg:w-full text-white font-extrabold text-md lg:text-3xl text-center lg:text-balance pb-4">
          Participa en las campañas de empresas que apoyan el reciclaje
        </h2>
        <Button
          onClick={() => setShowBanner(false)}
          variant={"ecolanaLearnMore"}
          className="min-w-fit hidden lg:flex lg:w-2/6 text-md"
        >
          Cerrar
        </Button>
      </div>
      <div className="hidden lg:flex flex-wrap justify-around items-center max-h-[25svh] ">
        <div className="w-5/12 flex justify-center items-center ">
          <Image src={miniBanner3} className="" alt="" />
        </div>
        <div className="w-5/12 flex justify-center items-center ">
          <Image src={miniBanner3} className="" alt="" />
        </div>
      </div>
      <CarouselCampaignImages
        className="lg:hidden lg:min-h-[25svh]"
        images={imgCarouselBanner}
        isBanner={false}
        pluginFlag={true}
        showBtns={false}
      />
    </section>
  );
};
