import {
  CollectionCenter,
  PropsCenter,
} from "@/interfaces/collectionCenterInterface";

export function getCollectionCenterData(
  collection_center: CollectionCenter
): PropsCenter {
  let id = collection_center.id;
  let phone =
    collection_center.contacts.data.length > 0
      ? collection_center.contacts.data[0].phone
      : null;
  const mapsLink = `https://www.google.com/maps/dir/?api=1&destination=${collection_center.address.data.lat},${collection_center.address.data.lng}`;
  const wazeLink = `https://waze.com/ul?ll=${collection_center.address.data.lat},${collection_center.address.data.lng}`;
  let name = collection_center.name;
  let schedule = collection_center.schedule;
  let benefit = collection_center.collection_center_benefit.data.name;
  let wastes =
    collection_center.wastes.data.length > 0
      ? collection_center.wastes.data.map((uniWaste) => uniWaste.name)
      : [];
  let address = collection_center.address.data.text_address;
  let description = collection_center.description;
  return {
    address,
    wastes,
    benefit,
    schedule,
    name,
    mapsLink,
    wazeLink,
    phone,
    description,
    id,
  };
}
