import { CardBGGradient } from "@/components/atoms";
import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import {
  CardGradientData,
  CardNumOverview,
  WasteValidationsContainer,
} from "@/components/molecules";
import { useCheckRefetch } from "@/hooks/useCheckRefetch";
import { useGraphicsData } from "@/hooks/useGraphicsData";
import { generateGraphic } from "@/shared/functions/generateGraphic";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import {
  dataGraphicsType,
  dataPerCollectionCenterType,
} from "@/interfaces";
import { Controller } from "react-hook-form";
import { CheckBoxGroup } from "../CheckBoxGroup/CheckBoxGroup";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import { useTypeCollectionCenters } from "@/hooks/useTypeCollectionCenters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup, faStar } from "@fortawesome/free-solid-svg-icons";
import { Dot, Star, StarIcon } from "lucide-react";
import { Separator } from "@/components/atoms/shadcn/ui/separator";
import { fetcherOverviewDataCollectionCentersRegistrations } from "@/pages/admins/collectioncenters/overview";

type ProductActivityProps = {
  session: Session | null;
};

export const CollectionCenterActivity = ({ session }: ProductActivityProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "average_per_user",
      "brands",
      "sub_brands",
      "waste_categories",
      "wastes",
      "users_top",
    ],
    keysYear: ["year_history"],
  });

  const { type_collection_center, control_centers } = useTypeCollectionCenters({
    default_type_collection_center: "global",
  });
  const {
    data: stats_collection_centers,
    refetch: refetch_collection_centers,
  } = useQuery({
    queryKey: ["overview_collection_centers"],
    enabled: session?.user?.token ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await fetcherOverviewDataCollectionCentersRegistrations({
            token: session?.user?.token!,
            ranges,
            global,
            years,
          })
        : null;
    },
  });
  const { data: stats_collectors, refetch: refetch_collectors } = useQuery({
    queryKey: ["overview_collectors"],
    enabled: session?.user?.token ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await fetcherOverviewDataCollectionCentersRegistrations({
            token: session?.user?.token!,
            ranges,
            global,
            years,
          })
        : null;
    },
  });

  const dataGraphics_1: dataGraphicsType = [
    {
      title: "Historial de validaciones por tipo de centro",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "line",
      name_graphic_data: "validation_center_types",
    },
  ];
  const dataPerCollectionCenter: dataPerCollectionCenterType = {
    global: [
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Estado",
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "states",
        variant: "collection_centers",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Estado",
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "states",
        variant: "green_points",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Municipio",
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "towns",
        variant: "collection_centers",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Municipio",
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "towns",
        variant: "green_points",
      },
      {
        title: "Centros de Acopio con más Validaciones (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_validations",
        variant: "collection_centers",
      },
      {
        title: "Puntos Verdes con más validaciones (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_validations",
        variant: "green_points",
      },
      {
        title:
          "Regla 80-15-5 (Dispersión del total de  Kg en Centros de Acopio)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 2,
        graphicType: "vertical_bar_comparative",
        name_graphic_data: "percents",
        variant: "collection_centers",
      },
      {
        title: "Regla 80-15-5 (Dispersión del total de  Kg en Puntos Verdes)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 2,
        graphicType: "vertical_bar_comparative",
        name_graphic_data: "percents",
        variant: "green_points",
      },
      {
        title: "Categorías  con mayor número de validaciones  (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_waste_categories",
        variant: "collection_centers",
      },
      {
        title: "Categorías  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_waste_categories",
        variant: "green_points",
      },
      {
        title: "Residuos  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 2,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_wastes",
        variant: "collection_centers",
      },
      {
        title: "Residuos  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 2,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_wastes",
        variant: "green_points",
      },
      {
        title: "Centros de Acopio vs  Categoría de residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_categories",
        variant: "collection_centers",
      },
      {
        title: "Puntos verdes vs  Categoría de residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_categories",
        variant: "green_points",
      },
      {
        title: "Centros de Acopio vs residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 2,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_wastes",
        variant: "collection_centers",
      },
      {
        title: "Puntos verdes vs residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 2,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_wastes",
        variant: "green_points",
      },
      {
        title: "Categorías con menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste_categories",
        variant: "green_points",
      },
      {
        title: "Categorías con menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste_categories",
        variant: "green_points",
      },
      {
        title: "Residuos con  menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste",
        variant: "collection_centers",
      },
      {
        title: "Residuos con  menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste",
        variant: "green_points",
      },
    ],
    collection_centers: [
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Estado",
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "states",
        variant: "collection_centers",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Municipio",
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "towns",
        variant: "collection_centers",
      },
      {
        title: "Centros de Acopio con más Validaciones (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_validations",
        variant: "collection_centers",
      },
      {
        title:
          "Regla 80-15-5 (Dispersión del total de  Kg en Centros de Acopio)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 2,
        graphicType: "vertical_bar_comparative",
        name_graphic_data: "percents",
        variant: "collection_centers",
      },
      {
        title: "Categorías  con mayor número de validaciones  (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_waste_categories",
        variant: "collection_centers",
      },
      {
        title: "Residuos  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_wastes",
        variant: "collection_centers",
      },
      {
        title: "Centros de Acopio vs  Categoría de residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_categories",
        variant: "collection_centers",
      },
      {
        title: "Residuos con  menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste",
        variant: "collection_centers",
      },
    ],
    collectors: [
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Estado",
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "states",
        variant: "collectors",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Municipio",
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "towns",
        variant: "collectors",
      },
      {
        title: "Centros de Acopio con más Validaciones (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_validations",
        variant: "collectors",
      },
      {
        title:
          "Regla 80-15-5 (Dispersión del total de  Kg en Centros de Acopio)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 2,
        graphicType: "vertical_bar_comparative",
        name_graphic_data: "percents",
        variant: "collectors",
      },
      {
        title: "Categorías  con mayor número de validaciones  (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_waste_categories",
        variant: "collectors",
      },
      {
        title: "Residuos  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_wastes",
        variant: "collectors",
      },
      {
        title: "Centros de Acopio vs  Categoría de residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_categories",
        variant: "collectors",
      },
      {
        title: "Residuos con  menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste",
        variant: "collectors",
      },
    ],
    green_points: [
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Estado",
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "states",
        variant: "green_points",
      },
      {
        title: "Ubicaciones con más validaciones (TOP 10)",
        subtitle: "*Municipio",
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "towns",
        variant: "green_points",
      },
      {
        title: "Centros de Acopio con más Validaciones (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_validations",
        variant: "green_points",
      },
      {
        title:
          "Regla 80-15-5 (Dispersión del total de  Kg en Centros de Acopio)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 2,
        graphicType: "vertical_bar_comparative",
        name_graphic_data: "percents",
        variant: "green_points",
      },
      {
        title: "Categorías  con mayor número de validaciones  (TOP 10) ",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_waste_categories",
        variant: "green_points",
      },
      {
        title: "Residuos  con mayor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "most_residue_wastes",
        variant: "green_points",
      },
      {
        title: "Centros de Acopio vs  Categoría de residuos (TOP 10 )",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar_stacked",
        name_graphic_data: "centers_categories",
        variant: "green_points",
      },
      {
        title: "Residuos con  menor número de validaciones  (TOP 10)",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        span: 1,
        graphicType: "horizontal_bar",
        name_graphic_data: "minor_residue_waste",
        variant: "green_points",
      },
    ],
  };

  function selecDataStats({
    variant,
    name_graphic_data,
    graphicType,
  }: {
    variant?: string;
    name_graphic_data: string;
    graphicType: string;
  }) {
    const stats: any =
      variant == "collection_center"
        ? stats_collection_centers
        : variant == "green_points"
        ? stats_collection_centers
        : stats_collectors;

    return stats
      ? name_graphic_data === "percents"
        ? stats[name_graphic_data]
        : (generateGraphic(
            stats as any,
            name_graphic_data,
            name_graphic_data == "validation_center_types" ? true : false,
            graphicType == "horizontal_bar_stacked"
          ) as any)
      : undefined;
  }

  return (
    <TabsContent value="Activity">
      <div className="flex w-full  flex-wrap items-center justify-around px-5 py-3  gap-4">
        <div className="w-full flex justify-end px-5 items-center">
          <FilterPerYearController name={"globalRange"} control={control} />
        </div>
      </div>
      <div className="grid grid-cols-2 px-5 py-2 gap-4 my-14">
        {dataGraphics_1.map((item, idx) => (
          <WasteValidationsContainer
            key={`graphicCell_${idx * Math.random() * 100}`}
            className={`${
              item.span == 1 ? "" : "col-span-2"
            } bg-admins-centers bg-opacity-15`}
            title={item.title}
            subtitle={item.subtitle}
            filter={
              <FilterPerYearController
                name={item.nameFilter}
                control={control}
              />
            }
          >
            <OverviewItem
              graphicType={item.graphicType}
              data={
                generateGraphic(
                  stats_collection_centers as any,
                  item.name_graphic_data,
                  false,
                  true
                ) as any
              }
            />
          </WasteValidationsContainer>
        ))}
        <div className="col-span-2 grid grid-cols-3 gap-4 py-5">
          <div className="col-span-3 flex flex-row space-x-3 justify-end">
            <Controller
              name={"type_collection_center"}
              control={control_centers}
              render={({ field: { name, onChange, value } }) => (
                <CheckBoxGroup
                  options={[
                    {
                      text_label: "Anáñisis Global",
                      slug: "global",
                    },
                    {
                      text_label: "Centros de Acopio",
                      slug: "collection_centers",
                    },
                    {
                      text_label: "Puntos verdes",
                      slug: "green_points",
                    },
                    {
                      text_label: "Recolectores",
                      slug: "collectors",
                    },
                  ]}
                  value={value}
                  setValue={onChange}
                />
              )}
            />
          </div>
          <div className="col-span-3 flex justify-around">
            <CardGradientData
              variant={"whiteDisabled"}
              icon_to_show={
                <FontAwesomeIcon
                  className="text-admins-text-active fa-xl aspect-square"
                  icon={faPeopleGroup}
                />
              }
              className="w-64 h-52 shadow-md aspect-square"
              scans_number={"1234"}
              text_card="Validan"
              showDownloadIcon={false}
            />
            <CardGradientData
              variant={"whiteDisabled"}
              icon_to_show={
                <FontAwesomeIcon
                  className="text-admins-text-active fa-xl aspect-square"
                  icon={faPeopleGroup}
                />
              }
              className="w-64 h-52 shadow-md aspect-square"
              scans_number={"1234"}
              text_card="No Validan"
              showDownloadIcon={false}
            />
          </div>
          <div className="col-span-3 flex flex-row space-x-3 justify-start">
            <div className="flex flex-row items-center">
              <Dot size={64} className="text-admins-collection_centers" />
              <p>Centros de acopio</p>
            </div>
            <div className="flex flex-row items-center">
              <Dot size={64} className="text-admins-green_points" />
              <p>Puntos verdes</p>
            </div>
            <div className="flex flex-row items-center">
              <Dot size={64} className="text-admins-collectors" />
              <p>Recolectores</p>
            </div>
          </div>
          <WasteValidationsContainer
            className={``}
            title={"Kilos/toneladas  totales reciclados"}
            filter={
              <FilterPerYearController
                name={"graphsRanges"}
                control={control}
              />
            }
          >
            {(type_collection_center == "global" ||
              type_collection_center == "collection_centers") && (
              <CardNumOverview
                variant={"collection_centers"}
                className="w-1/2 aspect-square items-center"
              />
            )}
            {(type_collection_center == "global" ||
              type_collection_center == "green_points") && (
              <CardNumOverview
                variant={"green_points"}
                className="w-1/2 aspect-square items-center"
              />
            )}
            {type_collection_center == "collectors" && (
              <CardNumOverview
                variant={"collectors"}
                className="w-1/2 aspect-square items-center"
              />
            )}
          </WasteValidationsContainer>
          <WasteValidationsContainer
            className={``}
            title={"Promedio de validaciones por centro de acopio"}
            filter={
              <FilterPerYearController
                name={"graphsRanges"}
                control={control}
              />
            }
          >
            {(type_collection_center == "global" ||
              type_collection_center == "collection_centers") && (
              <CardNumOverview
                variant={"collection_centers"}
                className="w-1/2 aspect-square items-center"
              />
            )}
            {(type_collection_center == "global" ||
              type_collection_center == "green_points") && (
              <CardNumOverview
                variant={"green_points"}
                className="w-1/2 aspect-square items-center"
              />
            )}
            {type_collection_center == "collectors" && (
              <CardNumOverview
                variant={"collectors"}
                className="w-1/2 aspect-square items-center"
              />
            )}
          </WasteValidationsContainer>
          <WasteValidationsContainer
            className={``}
            title={"Promedio de Kg por validación"}
            filter={
              <FilterPerYearController
                name={"graphsRanges"}
                control={control}
              />
            }
          >
            {(type_collection_center == "global" ||
              type_collection_center == "collection_centers") && (
              <CardNumOverview
                variant={"collection_centers"}
                className="w-1/2 aspect-square items-center"
              />
            )}
            {(type_collection_center == "global" ||
              type_collection_center == "green_points") && (
              <CardNumOverview
                variant={"green_points"}
                className="w-1/2 aspect-square items-center"
              />
            )}
            {type_collection_center == "collectors" && (
              <CardNumOverview
                variant={"collectors"}
                className="w-1/2 aspect-square items-center"
              />
            )}
          </WasteValidationsContainer>
        </div>
        {dataPerCollectionCenter[type_collection_center]?.map((item, idx) => (
          <WasteValidationsContainer
            key={`graphicCell_${idx * Math.random() * 100}`}
            className={`${item.span == 1 ? "" : "col-span-2"}`}
            variant={item.variant}
            title={item.title}
            subtitle={item.subtitle}
            filter={
              <FilterPerYearController
                name={item.nameFilter}
                control={control}
              />
            }
          >
            <OverviewItem
              graphicType={item.graphicType}
              data={selecDataStats({
                graphicType: item.graphicType,
                name_graphic_data: item.name_graphic_data,
                variant: item.variant,
              })}
            />
          </WasteValidationsContainer>
        ))}
        <WasteValidationsContainer
          variant={"collection_centers"}
          title={"Calificación promedio"}
        >
          <div className="flex flex-wrap w-2/3 h-full items-center justify-around min-h-32">
            <p className=" text-admins-text-active text-5xl items-center font-extrabold">
              5.0
            </p>
            <Separator
              orientation="vertical"
              className="bg-admins-text-active w-1"
            />
            <FontAwesomeIcon
              className="text-admins-text-active fa-2xl"
              icon={faStar}
            />
          </div>
        </WasteValidationsContainer>
      </div>
    </TabsContent>
  );
};
