import { InputHTMLAttributes } from "react";
import { Input, inputVariants } from "../../atoms/shadcn/ui/input";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
export type Props<T extends FieldValues> = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "name"
> &
  VariantProps<typeof inputVariants> & {
    error?: string;
    label?: string;
    control: Control<T, Object>;
    name: Path<T>;
    isEnable?: boolean;
    errors?: string;
  };
export const ControlledInput = <T extends FieldValues>({
  error,
  label,
  name,
  control,
  isEnable = true,
  variant,
  placeholder,
  className,
  errors,
  ...props
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return isEnable ? (
          <div className={cn(className, "flex flex-col")}>
            <Input
              {...props}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              variant={variant}
              placeholder={placeholder}
            />
            <p className="text-ecolana-wasteRed font-semibold">{errors}</p>
          </div>
        ) : (
          <p {...props}>{field.value}</p>
        );
      }}
    />
  );
};
