import { DialogAdmins } from "@/components/atoms";
import { FormCreateCC } from "../FormCreateCC/FormCreateCC";
import { FormCreateCollector } from "../FormCreateCollector/FormCreateCollector";
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFormWatch,
} from "react-hook-form";
import {
  CC_Form,
  ScheduleForm,
  Settlement,
  States,
  Towns,
  WasteId,
  ZipCodes,
} from "@/interfaces";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { useEffect, useState } from "react";
import { ControlledInput } from "@/components/molecules";
import { Label } from "@/components/atoms/shadcn/ui/label";
type DialogNewScheduleProps = {
  isOpen: boolean;
  onClose: () => void;
  control: Control<ScheduleForm, any>;
  watch: UseFormWatch<ScheduleForm>;
  fields: FieldArrayWithId<ScheduleForm, "schedule_days", "id">[];
  append: UseFieldArrayAppend<ScheduleForm, "schedule_days">;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<ScheduleForm, "schedule_days">;
  handleSubmit: (data: any) => void;
};

interface Day {
  name: string;
  key: string;
}

interface ScheduleState {
  [key: string]: {
    start: number | null;
    end: number | null;
  };
}

const daysOfWeek: Day[] = [
  { name: "Lunes", key: "monday" },
  { name: "Martes", key: "tuesday" },
  { name: "Miércoles", key: "wednesday" },
  { name: "Jueves", key: "thursday" },
  { name: "Viernes", key: "friday" },
  { name: "Sábado", key: "saturday" },
  { name: "Domingo", key: "sunday" },
];

const hours: number[] = Array.from({ length: 13 }, (_, i) => i + 8); // Horas desde las 8:00 a las 20:00

export const DialogNewSchedule = ({
  isOpen,
  onClose,
  control,
  fields,
  watch,
  append,
  remove,
  update,
  handleSubmit,
}: DialogNewScheduleProps) => {
  const [schedule, setSchedule] = useState<ScheduleState>(
    daysOfWeek.reduce((acc, day) => {
      acc[day.key] = { start: null, end: null };
      return acc;
    }, {} as ScheduleState)
  );
  useEffect(() => {
    setSchedule(
      daysOfWeek.reduce((acc, day) => {
        acc[day.key] = { start: null, end: null };
        return acc;
      }, {} as ScheduleState)
    );
    remove();
  }, [isOpen]);

  const handleHourClick = (day: string, hour: number) => {
    const { start, end } = schedule[day];
    if (start === null || end !== null) {
      setSchedule({
        ...schedule,
        [day]: { start: hour, end: null },
      });
    } else {
      setSchedule({
        ...schedule,
        [day]: { start, end: hour >= start ? hour : null },
      });
      if (hour >= start) {
        const isDayExist = watch("schedule_days").findIndex(
          (uni_day) => uni_day.day == day
        );

        if (isDayExist > -1) {
          update(isDayExist, {
            day: day,
            start_time: start,
            end_time: hour,
          });
        } else {
          append({
            day: day,
            start_time: start,
            end_time: hour,
          });
        }
      }
    }
  };

  const isHourSelected = (day: string, hour: number): boolean => {
    const { start, end } = schedule[day];
    return start !== null && end !== null && hour >= start && hour <= end;
  };

  const isHourStartOrEnd = (day: string, hour: number): boolean => {
    const { start, end } = schedule[day];
    return start === hour || end === hour;
  };

  return (
    <DialogAdmins
      isOpen={isOpen}
      onClose={onClose}
      title="Formato horarios de operación"
      variant={"green"}
    >
      <div className="container flex flex-wrap justify-center mx-auto gap-3 p-4">
        <div className="grid grid-cols-2 gap-4 w-1/2 items-center px-5">
          <Label>Nombre: </Label>
          <ControlledInput
            control={control}
            name="name"
            variant={"adminGray"}
          />
        </div>
        <h1 className="text-2xl w-full text-center font-bold mb-4">Selecciona Horarios</h1>
        <div className="grid grid-cols-1 md:grid-cols-7 gap-4">
          {daysOfWeek.map((day) => (
            <div
              key={day.key}
              className="grid grid-cols-1 p-3 gap-4 border justify-center rounded-lg shadow-sm"
            >
              <h2 className="text-lg text-center font-semibold mb-2">
                {day.name}
              </h2>
              {hours.map((hour) => (
                <Button
                  variant={"adminGhost"}
                  key={hour}
                  type="button"
                  onClick={() => handleHourClick(day.key, hour)}
                  className={`w-full py-2 mb-1 rounded ${
                    isHourSelected(day.key, hour)
                      ? "bg-ecolana-mediumGreen text-white"
                      : "bg-transparent"
                  } ${
                    isHourStartOrEnd(day.key, hour)
                      ? "border-2 border-ecolana-mediumGreen"
                      : ""
                  }`}
                >
                  {hour}:00
                </Button>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Button variant={"adminDownload"} onClick={() => handleSubmit(schedule)}>
        Guardar
      </Button>
    </DialogAdmins>
  );
};
