import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/shadcn/ui/tabs";
import React from "react";
import { DataPerCardMKT } from "../DataPerCardMKT/DataPerCardMKT";
import {
  AppActivityTab,
  DemographicsTab,
  RewardBadgeTab,
} from "../MarketingTabs";

export const MarketingOverviewTabs = () => {
  return (
    <Tabs
      defaultValue="app_activity"
      className="w-full flex flex-wrap items-center align-middle justify-center gap-5"
    >
      <TabsList className="col-span-2 xl:col-span-1 xl:col-start-2 justify-self-center w-fit h-14 justify-between rounded-2xl px-4 space-x-5">
        <TabsTrigger
          variant={"adminsTab"}
          className="w-1/2"
          value="app_activity"
        >
          Actividad APP
        </TabsTrigger>
        <TabsTrigger variant={"adminsTab"} className="w-1/2" value="rewards">
          Recompensas
        </TabsTrigger>
        <TabsTrigger
          variant={"adminsTab"}
          className="w-1/2"
          value="app_demographics"
        >
          Demográficos App
        </TabsTrigger>
        {/* <TabsTrigger variant={"adminsTab"} className="w-1/2" value="searches">
          Búsquedas
        </TabsTrigger> */}
      </TabsList>
      <AppActivityTab  />
      <RewardBadgeTab />
      <DemographicsTab />
    </Tabs>
  );
};
