import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/atoms/shadcn/ui/carousel";
import { BadgeCampaignCard } from "@/components/molecules";

export const CarouselBadges = ({
  badges = [
    { isBadge: true, isCampaign: true },
    { isBadge: false, isCampaign: true },
    { isBadge: false, isCampaign: true },
    // { isBadge: true, isCampaign: true },
    // { isBadge: false, isCampaign: true },
    // { isBadge: false, isCampaign: true },
  ],
}: {
  badges?: any[];
}) => {
  return (
    <>
      <div className="flex w-full justify-center px-3 lg:px-8 items-center">
        <Carousel opts={{ loop: true, align: "start" }} className="flex w-full">
          <CarouselContent className="-ml-2 md:-ml-4">
            <BadgesItems badges={badges} />
          </CarouselContent>
        </Carousel>
      </div>
    </>
  );
};

const BadgesItems = ({ badges }: { badges: any[] }) => {
  return badges.map((uni_badge, index) => (
    <CarouselItem
      className={`${uni_badge.isBadge ? "basis-11/12 lg:basis-9/12" : "basis-11/12 lg:basis-4/12"}`}
      key={index}
    >
      <BadgeCampaignCard
        key={`badge_card_${index}`}
        badgeInfo={uni_badge}
        isSelected
      />
    </CarouselItem>
  ));
};
