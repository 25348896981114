import {
  CardNumOverview,
  WasteValidationsContainer,
} from "@/components/molecules";
import { cn } from "@/lib/utils";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { useGraphicsData } from "@/hooks";
import { CarouselCampaignBadgesTabs } from "../CarouselCampaignBadgesTabs/CarouselCampaignBadgesTabs";

interface DataPerCardMKTProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onlyCards?: boolean;
}
const dataArray = [1, 2, 3];
export const DataPerCardMKT = ({
  className,
  onlyCards,
}: DataPerCardMKTProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "average_per_user",
      "brands",
      "sub_brands",
      "waste_categories",
      "wastes",
      "users_top",
    ],
    keysYear: ["year_history"],
  });
  return (
    <div className={cn(className)}>
      {onlyCards ? (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
          {dataArray.map((uni_data, index) => (
            <WasteValidationsContainer
              className={`w-full`}
              key={`uni_bagse_num_${index}`}
              title={"Kilos/toneladas  totales reciclados"}
              filter={
                <FilterPerYearController
                  name={"graphsRanges"}
                  control={control}
                />
              }
            >
              <CardNumOverview
                variant={"collection_centers"}
                className="w-1/2 aspect-square items-center"
              />
            </WasteValidationsContainer>
          ))}
        </div>
      ) : (
        <CarouselCampaignBadgesTabs />
      )}
    </div>
  );
};
