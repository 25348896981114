import { Separator } from "@/components/atoms/shadcn/ui/separator";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { ReactNode } from "react";
import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import { Tabs } from "@/components/atoms/shadcn/ui/tabs";
const WasteValidationsContainerVariant = cva(
  "flex flex-wrap p-2 shadow-lg rounded-2xl",
  {
    variants: {
      variant: {
        default: "bg-white",
        collection_centers: "bg-admins-collection_centers bg-opacity-15",
        green_points: "bg-admins-green_points bg-opacity-15",
        collectors: "bg-admins-collectors bg-opacity-15",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);
interface WasteValidationsContainerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    VariantProps<typeof WasteValidationsContainerVariant> {
  children?: ReactNode;
  title: string;
  filter?: JSX.Element;
  subtitle?: string;
  defaultValueTabName?: string;
  onValueTabChange?: ((value: string) => void) | undefined;
}
export const WasteValidationsContainer = ({
  className,
  title,
  children,
  filter,
  subtitle,
  variant,
  defaultValueTabName,
  onValueTabChange,
}: WasteValidationsContainerProps) => {
  return (
    <Tabs
      defaultValue={defaultValueTabName}
      className={cn(WasteValidationsContainerVariant({ variant, className }))}
      onValueChange={onValueTabChange}
      orientation="vertical"
    >
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-2 justify-evenly py-3 items-center w-[100%]">
        <div className="col-span-2 md:col-span-1">
          <p className="text-admins-text-active text-xl font-bold">{title}</p>
          {subtitle && (
            <p className="text-admins-text-active text-xxs">{subtitle}</p>
          )}
        </div>
        {filter && filter}
      </div>
      <Separator className="bg-[#E6E8F0]" orientation="horizontal" />
      <div className="w-full flex flex-wrap py-2 items-center justify-center">
        {children}
      </div>
    </Tabs>
  );
};
