import { collection_center_icon, logoTrashLater } from "@/assets";
import { CollapsibleMenu } from "@/components/atoms";
import {
  faBagShopping,
  faBasketShopping,
  faChartBar,
  faChartColumn,
  faChartPie,
  faChartSimple,
  faSquarePollVertical,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScanBarcode } from "lucide-react";
import Link from "next/link";
import React from "react";
import Image from "next/image";

type AdminRoutesProps = {
  name: string;
  icon: JSX.Element;
  sub_options: {
    label: string;
    path: string;
    isSuboptions?: boolean;
    sub_options?: { label: string; path: string }[];
  }[];
}[];

const adminsRoutes: AdminRoutesProps = [
  {
    name: "Productos",
    icon: (
      <FontAwesomeIcon
        className="text-admins-text-active"
        icon={faBagShopping}
      />
    ),
    sub_options: [
      { label: "Overview", path: "/admins/products/overview" },
      { label: "Transacciones", path: "/admins/products/transactions" },
    ],
  },
  {
    name: "Compras (Tickets)",
    icon: (
      <FontAwesomeIcon className="text-admins-text-active" icon={faTicket} />
    ),
    sub_options: [
      { label: "Overview", path: "/admins/compras/overview" },
      { label: "Transacciones", path: "/admins/compras/transactions" },
    ],
  },
  {
    name: "Escaner",
    icon: <ScanBarcode className="text-admins-text-active" />,
    sub_options: [
      { label: "Overview", path: "/admins/escaner/overview" },
      { label: "Transacciones", path: "/admins/escaner/transactions" },
    ],
  },
  {
    name: "Centros de Acopio",
    icon: (
      <Image
        src={collection_center_icon}
        className=""
        alt="collection_centers_icon"
      />
    ),
    sub_options: [
      { label: "Overview", path: "/admins/collectioncenters/overview" },
      {
        label: "Validaciones",
        path: "/admins/collectioncenters/validations",
      },

      {
        label: "Inventario",
        path: "/admins/collectioncenters/inventory",
        isSuboptions: true,
        sub_options: [
          {
            label: "Alta centros de acopio/Inventario",
            path: "/admins/collectioncenters/registercenters",
          },
        ],
      },
    ],
  },
  // {
  //   name: "Marketing",
  //   icon: (
  //     <FontAwesomeIcon
  //       className="text-admins-text-active"
  //       icon={faChartSimple}
  //     />
  //   ),
  //   sub_options: [
  //     { label: "Overview general", path: "/admins/marketing/overview" },
  //     {
  //       label: "Overview insignias / campañas",
  //       path: "/admins/marketing/overview_badges",
  //     },
  //     // { label: "Clientes escaner", path: "/admins/marketing/client_scanner" },
  //     // { label: "Alta insignias", path: "/admins/marketing/register_badges" },
  //     // { label: "Alta campañas", path: "/admins/marketing/register_campaign" },
  //   ],
  // },
];

export const SideBarOptions = () => {
  return (
    <>
      <div className="flex flex-col w-full px-3 py-4 space-y-5 ">
        <Link href={"/admins/overview"}>
          <div className="flex flex-wrap px-4 items-center space-x-5">
            <FontAwesomeIcon
              className="text-admins-text-overview"
              icon={faChartPie}
            />
            <p className="text-admins-text-active">Overview</p>
          </div>
        </Link>
        {adminsRoutes.map((uni_admin_route) => (
          <CollapsibleMenu
            key={`CollapsibleMenu_${uni_admin_route.name}_button`}
            icon_to_show={uni_admin_route.icon}
            main_option={uni_admin_route.name}
            sub_options={uni_admin_route.sub_options}
          />
        ))}
      </div>
      <div className="flex flex-col w-full px-3 py-4 mt-4 h-1/2  space-y-2 font-medium border-t border-admins-text-active">
        <CollapsibleMenu
          icon_to_show={
            <Image
              src={collection_center_icon}
              className=""
              alt="collection_centers_icon"
            />
          }
          main_option="Generales"
          sub_options={[
            {
              label: "SKU's",
              path: "/admins/general/sku/overview",
              isSuboptions: true,
              sub_options: [
                { label: "Overview", path: "/admins/general/sku/overview" },
                {
                  label: "Catálogo de SKU's",
                  path: "/admins/general/sku/catalog",
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
};
