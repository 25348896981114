import { FC } from "react";
import { Marker, MarkerDragEvent } from "react-map-gl";

interface Props {
  lat: number;
  lng: number;
  pin: string;
  onMarkerDrag: (event: MarkerDragEvent) => void;
}

export const DraggableMarker: FC<Props> = ({ lat, lng, pin, onMarkerDrag }) => {
  return (
    <Marker
      longitude={lng}
      latitude={lat}
      anchor="bottom"
      style={{ justifyContent: "center", alignItems: "center" }}
      draggable
      onDrag={onMarkerDrag}
    >
      <img src={pin} alt="pin" />
    </Marker>
  );
};
