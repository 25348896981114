import { AdminLayout } from "@/components";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/shadcn/ui/tabs";
import { CardGradientData } from "@/components/molecules";
import {
  CollectionCenterActivity,
  CollectionCenterDemographics,
} from "@/components/organisms";
import { dataServices } from "@/hooks/dataServices";
import { FetcherOverviewData } from "@/interfaces";
import {
  formatData,
  formatDataComplexNew,
  formatNumber,
  formatParamsForFilters,
} from "@/shared/functions/formData";
import { colors_admin } from "@/styles/fullConfig";
import { faPagelines } from "@fortawesome/free-brands-svg-icons";
import { faPeopleGroup, faPeopleRoof } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { FileBarChart2 } from "lucide-react";
import { useSession } from "next-auth/react";

export async function fetcherOverviewDataCollectionCentersRegistrations({
  token,
  ranges,
  global,
  years,
}: FetcherOverviewData) {
  const { localRanges, localYears } = formatParamsForFilters(
    ranges,
    global,
    years
  );
  const response = await dataServices({
    url: "/collection_centers/overview",
    token,
    params: {
      filters: {
        ...localRanges,
        ...localYears,
      },
    },
  });
  let data = {} as any;

  function compararPorcentajes(a: string, b: string) {
    let numA = parseFloat(a.replace("%", ""));
    let numB = parseFloat(b.replace("%", ""));
    return numB - numA;
  }

  const percents = response.data
    .filter((item: any) => item.graph == "percents")
    .sort((a: any, b: any) => compararPorcentajes(a.label, b.label));
  data = {
    percents: {
      labels: percents.map((item: any) => item.label),
      datasets: [
        {
          label: "Centros de Acopio",
          data: percents.map((item: any) => Number(item.value)),
          backgroundColor: colors_admin.graphics.scans_with_badge,
        },
        {
          label: "Validaciones",
          data: percents.map((item: any) => Number(item.range)),
          backgroundColor: colors_admin.graphics.scans_without_badge,
        },
      ],
    },
  };
  const remove_percents_data = response.data.filter(
    (item: any) => item.graph != "percents"
  );
  return {
    ...formatData(remove_percents_data),
    ...data,
  };
}

export async function fetcherOverviewDemographicsCollectionCentersRegistrations({
  token,
  ranges,
  global,
  years,
}: FetcherOverviewData) {
  const { localRanges } = formatParamsForFilters(ranges, global, years);
  const response = await dataServices({
    url: "/collection_centers/demographics_stats",
    token,
    params: {
      filters: {
        ...localRanges,
      },
    },
  });
  return {
    ...formatData(response.data),
  };
}

function CollectionCentersOverview() {
  const { data: session } = useSession();
  const { data } = useQuery({
    queryKey: ["totals_overview_shopping"],
    enabled: session?.user?.token ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await dataServices({
            url: "/collection_centers/overview_totals",
            token: session?.user?.token!,
          })
        : { data: {} };
    },
  });
  return (
    <Tabs defaultValue="Activity">
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 items-end z-30 py-10 px-10 w-full">
        <CardGradientData
          variant={"whiteDisabled"}
          icon_to_show={
            <FontAwesomeIcon
              className="text-admins-text-active fa-xl aspect-square"
              icon={faPeopleRoof}
            />
          }
          className="shadow-md h-full"
          scans_number={formatNumber(data?.centers)}
          text_card="Total Centros de Acopio"
        />
        <CardGradientData
          variant={"whiteDisabled"}
          icon_to_show={
            <FontAwesomeIcon
              className="text-admins-text-active fa-xl aspect-square"
              icon={faPeopleGroup}
            />
          }
          className="shadow-md h-full"
          scans_number={formatNumber(data?.collectors)}
          text_card="Total de Recolectores"
        />
        <CardGradientData
          variant={"whiteDisabled"}
          icon_to_show={
            <FontAwesomeIcon
              scale={12}
              className="text-admins-text-active fa-xl aspect-square"
              icon={faPagelines}
            />
          }
          className="shadow-md h-full"
          scans_number={formatNumber(data?.green_points)}
          text_card="Total Puntos verdes"
        />
        <CardGradientData
          variant={"whiteDisabled"}
          icon_to_show={
            <FileBarChart2 className="text-admins-text-active h-7 w-7" />
          }
          className="shadow-md h-full"
          scans_number={formatNumber(data?.user_centers)}
          text_card="Total CA (usuarios)"
        />
        <div className="col-span-4 flex h-full items-end justify-end w-full">
          <TabsList className="w-fit h-14 justify-between rounded-2xl px-4 space-x-5">
            <TabsTrigger
              variant={"adminsTab"}
              className="w-1/2"
              value="Activity"
            >
              Actividad
            </TabsTrigger>
            <TabsTrigger
              variant={"adminsTab"}
              className="w-1/2"
              value="Demographics"
            >
              Demográficos
            </TabsTrigger>
          </TabsList>
        </div>
      </div>
      <CollectionCenterActivity session={session} />
      <CollectionCenterDemographics session={session} />
    </Tabs>
  );
}

export default CollectionCentersOverview;

CollectionCentersOverview.getLayout = function getLayout(
  page: React.ReactNode
) {
  return <AdminLayout>{page}</AdminLayout>;
};
