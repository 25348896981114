import { columnWastesAccepted } from "@/components/columns";
import { DataTable, WasteValidationsContainer } from "@/components/molecules";
import { usePagination } from "@/hooks";
import React from "react";

export const TableCardWastes = () => {
  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    pageSize,
    onChangePageSize,
  } = usePagination(9);
  const pagination = {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    paginationLabel,
    last_page,
    onChangePageSize,
    pageSize,
  };
  return (
    <WasteValidationsContainer
      className={`w-full col-span-2`}
      key={`uni_bagse_num_`}
      title={"Residuos Participantes"}
    >
      <DataTable
        pagination={pagination}
        columns={columnWastesAccepted(() => {})}
        data={[]}
      />
    </WasteValidationsContainer>
  );
};
