import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import React from "react";
import { TableCardWastes } from "../TableCardWastes/TableCardWastes";
import { dataGraphicsType } from "@/interfaces";
import { WasteValidationsContainer } from "@/components/molecules";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";

const dataGraphicsDemographicsApp: dataGraphicsType = [
  {
    title: "Género",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "doughnut",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Acumulación de KG por GÉNERO",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "doughnut",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Edad",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "vertical_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Acumulación de KG por EDAD",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "vertical_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Ubicaciones de usuarios (TOP 10)",
    subtitle: "*Estado",
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Ubicaciones de usuarios (TOP 10)",
    subtitle: "*Municipio",
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Residuos con más validaciones por EDAD (TOP 10)",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar_stacked",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Residuos con más validaciones por GÉNERO (TOP 10)",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar_stacked",
    name_graphic_data: "validation_center_types",
  },
];

const dataGraphicsDemographicsBadgeApp: dataGraphicsType = [
  {
    title: "Género",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "doughnut",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Edad",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "vertical_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Ubicaciones de usuarios (TOP 10)",
    subtitle: "*Estado",
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Ubicaciones de usuarios (TOP 10)",
    subtitle: "*Municipio",
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar",
    name_graphic_data: "validation_center_types",
  },
];
export const DemographicsTab = () => {
  return (
    <TabsContent
      value="app_demographics"
      className="w-full grid grid-cols-2 gap-4 px-3"
    >
      {dataGraphicsDemographicsApp.map((item, idx) => (
        <WasteValidationsContainer
          key={`graphicCell_${idx * Math.random() * 100}`}
          className={`${
            item.span == 1 ? "" : "col-span-2"
          } bg-admins-centers bg-opacity-15`}
          title={item.title}
          subtitle={item.subtitle}
          filter={
            //   <FilterPerYearController
            //     name={item.nameFilter}
            //     control={control}
            //   />
            <></>
          }
        >
          <OverviewItem
            graphicType={item.graphicType}
            data={
              // generateGraphic(
              //   stats_collection_centers as any,
              //   item.name_graphic_data,
              //   false,
              //   true
              // ) as any
              undefined
            }
          />
        </WasteValidationsContainer>
      ))}
      <p className="text-admins-text-active font-bold text-xl col-span-2">
        Usuarios con búsquedas en INSIGNIA
      </p>
      {dataGraphicsDemographicsBadgeApp.map((item, idx) => (
        <WasteValidationsContainer
          key={`graphicCell_${idx * Math.random() * 100}`}
          className={`${
            item.span == 1 ? "" : "col-span-2"
          } bg-admins-centers bg-opacity-15`}
          title={item.title}
          subtitle={item.subtitle}
          filter={
            //   <FilterPerYearController
            //     name={item.nameFilter}
            //     control={control}
            //   />
            <></>
          }
        >
          <OverviewItem
            graphicType={item.graphicType}
            data={
              // generateGraphic(
              //   stats_collection_centers as any,
              //   item.name_graphic_data,
              //   false,
              //   true
              // ) as any
              undefined
            }
          />
        </WasteValidationsContainer>
      ))}
    </TabsContent>
  );
};
